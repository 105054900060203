import React from "react";
import "./ProjectWindow.scss";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import MasterButton from "./MasterButton";
import { BiLinkExternal } from "react-icons/bi";
import Tooltip from "./small/Tooltip";

function ProjectWindow(props) {
  const project = props.props.fields;

  console.log("project: ", project);

  const imageUrl = project?.displayImage?.fields?.file?.url
    ? `https:${project.displayImage.fields.file.url}`
    : `https:${project.thumbnail.fields.file.url}`;

  console.log("imageUrl: ", imageUrl);

  return (
    <div className="project-window">
      {/* Image */}
      <div className="project-window-img">
        {project?.linkToWebsite ? (
          <a
            href={project.linkToWebsite}
            target="_blank"
            rel="noopener noreferrer"
            className="project-window-img-link"
          >
            <img src={imageUrl} alt={project.thumbnail.fields.title} />
            <div className="overlay">
              <p>View Project </p>
              <BiLinkExternal className="link-icon" />
            </div>
          </a>
        ) : (
          <img src={imageUrl} alt={project.thumbnail.fields.title} />
        )}
      </div>

      <div className="container-link-tech">
        {/* Technologies */}
        <div className="project-window-technologies">
          {project.technologies.map((tech, index) => (
            <div key={index}>{renderTech(tech.fields)}</div>
          ))}
        </div>
        <div className="links">
          {project.linkToGitHub && (
            <Tooltip text="GitHub" position="bottom">
              <a
                href={project.linkToGitHub}
                target="_blank"
                rel="noopener noreferrer"
                className="link-icon-github"
              >
                {props.windowDimensions.width <= 500 ? (
                  <p>View on GitHub</p>
                ) : null}
                <BsGithub className="link-icon" />
              </a>
            </Tooltip>
          )}
          {project.linkToWebsite && (
            <a
              href={project.linkToWebsite}
              target="_blank"
              rel="noopener noreferrer"
              id="highlight"
            >
              <Tooltip text="Website" position="bottom">
                <MasterButton>
                  {props.windowDimensions.width <= 500 ? (
                    <p>View Website</p>
                  ) : null}
                  <BiLinkExternal className="link-icon" />
                </MasterButton>
              </Tooltip>
            </a>
          )}
        </div>
      </div>

      {/* Description */}
      <div className="project-window-text">
        {renderContent(props.props.fields.description.content)}
      </div>
    </div>
  );
}

export default ProjectWindow;

const renderContent = (contentArray) => {
  return contentArray.map((contentItem, index) => {
    switch (contentItem.nodeType) {
      case "heading-2":
        return (
          <h2 key={index} style={{ marginTop: "1em", marginBottom: "0.5em" }}>
            {contentItem.content.map((subItem) => subItem.value).join("")}
          </h2>
        );
      case "paragraph":
        const paragraphContent = contentItem.content.reduce(
          (acc, subItem, subIndex) => {
            let element;
            if (subItem.marks.some((mark) => mark.type === "bold")) {
              element = (
                <strong key={subIndex} style={{ fontWeight: "800" }}>
                  {subItem.value}
                  <br />
                </strong>
              );
            } else {
              element = (
                <span key={subIndex}>
                  {subItem.value}
                  <br />
                  <br />
                </span>
              );
            }

            return acc === null ? [element] : [...acc, " ", element];
          },
          null
        );

        return (
          <p key={index} style={{ marginBottom: "1em" }}>
            {paragraphContent}
          </p>
        );
      default:
        return null;
    }
  });
};

const renderTech = (tech) => {
  return (
    <div className="technology">
      <img src={`https:${tech?.image?.fields?.file?.url}`} alt={""} />
      {tech.name && <p>{tech.name}</p>}
    </div>
  );
};
