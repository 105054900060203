import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "../../navbar.scss";
import { IoPersonSharp, IoSchoolSharp } from "react-icons/io5";
import { RiHome2Fill, RiMenuLine } from "react-icons/ri";
import { IoIosListBox } from "react-icons/io";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { scroller } from "react-scroll";
import { slide as Menu } from "react-burger-menu";

function NavBarMobile() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    const position = Math.max(0, window.pageYOffset - 300); //Offset
    setScrollPosition(position);
  };

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Function to close the menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 1100,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <div className="mobile-menu-container">
      <div
        className="navbar-mobile"
        style={{
          backgroundColor:
            "rgba(10,12,23, " + Math.min(0.8, scrollPosition / 800) + ")",
        }}
      />
      <div
        className="menu-icon-mobile"
        onClick={toggleMenu}
        style={{ right: menuOpen ? "140px" : "20px" }}
      >
        <BurgerIcon isOpen={menuOpen} />
      </div>
      <div className="menu-icon-mobile-hero">
        <Link to={"/"}>
          <div className="logo" onClick={() => scrollTo("Hero")}>
            <img src="./logo.webp" alt="Axel" />
          </div>
        </Link>
      </div>
      <Menu
        right
        isOpen={menuOpen}
        onStateChange={handleStateChange}
        className="menu-icon"
        width={"200px"}
      >
        <div className="menu-sections">
          <div
            className="item"
            onClick={() => {
              scrollTo("Hero");
              toggleMenu();
            }}
          >
            <Link to={"/"} className="link">
              <RiHome2Fill className="menu-icon" />
              Home
            </Link>
          </div>
          <div
            className="item"
            onClick={() => {
              scrollTo("AboutMe");
              toggleMenu();
            }}
          >
            <Link to={"/"} className="link">
              <IoPersonSharp className="menu-icon" />
              About Me
            </Link>
          </div>
          <div
            className="item"
            onClick={() => {
              scrollTo("Projects");
              toggleMenu();
            }}
          >
            <Link to={"/"} className="link">
              <IoIosListBox className="menu-icon" />
              Projects
            </Link>
          </div>
          <div
            className="item"
            onClick={() => {
              scrollTo("Education");
              toggleMenu();
            }}
          >
            <Link to={"/#education"} className="link">
              <IoSchoolSharp className="menu-icon" />
              Education
            </Link>
          </div>
        </div>
        <div>
          <p>Links</p>
          <div className="menu-icon-links">
            <a
              href="https://www.linkedin.com/in/axel-nielsen-665a3811a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin />
              <p>LinkedIn</p>
            </a>
            <a
              href="https://github.com/AxelNie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsGithub />
              <p>GitHub</p>
            </a>
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default NavBarMobile;

const BurgerIcon = ({ isOpen }) => {
  return (
    <div className={`burger-icon ${isOpen ? "open" : ""}`}>
      <div className="burger-line" />
      <div className="burger-line" />
      <div className="burger-line" />
    </div>
  );
};
