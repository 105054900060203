import React, { memo, useState, useCallback } from "react";
import "./MasterButton.scss";
import PulseLoader from "react-spinners/PulseLoader";

const MasterButton = memo(({ onClick, loading, children }) => {
  const [fade, setFade] = useState(false);

  const handleClick = useCallback(() => {
    setFade(true);
    onClick && onClick();
  }, [onClick]);

  return (
    <button onClick={handleClick} className="master-button">
      {loading ? (
        <div className="loader">
          <PulseLoader color="hsla(168, 0%, 100%, 1)" size="10px" />
        </div>
      ) : (
        <div className="button-text">{children}</div>
      )}
    </button>
  );
});

export default MasterButton;
