import React, { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Modal.scss";
import { IoClose } from "react-icons/io5";

const Modal = ({ title, isModalOpen, setIsModalOpen, children }) => {
  const scrollYRef = useRef(0);

  useEffect(() => {
    if (isModalOpen) {
      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      scrollYRef.current = scrollY; // Save scroll position when opening the modal
      const body = document.body;
      body.style.position = "fixed";
      body.style.width = "100%";
      body.style.top = `-${scrollY}`;
      window.scrollTo(0, parseInt(scrollYRef.current || "0") * 1);
    } else {
      const body = document.body;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollYRef.current || "0") * 1); // Use the saved scroll position when closing the modal
    }

    return () => {
      const body = document.body;
      body.style.position = "";
      body.style.top = "";
      window.scrollTo(0, parseInt(scrollYRef.current || "0") * 1); // Use the saved scroll position in the cleanup function as well
    };
  }, [isModalOpen]);

  return (
    <AnimatePresence mode="wait">
      {isModalOpen && (
        <motion.div
          initial={{ y: "100vh" }}
          animate={{ y: 0 }}
          exit={{ y: "100vh" }}
          transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }} // Adding custom easing
          className="modal-container"
        >
          <div className="modal-header">
            <div className="modal-header-content">
              <div className="modal-title">
                <p className="pre-title">project</p>
                <h1>{title}</h1>
              </div>
              <div
                className="close-button"
                onClick={() => setIsModalOpen(false)}
              >
                <IoClose className="close-icon" />
              </div>
            </div>
          </div>
          <div className="modal-content">{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
