import React, { useState, useRef, useEffect } from "react";
import "./Footer.scss";
import "aos/dist/aos.css";
import { BsLinkedin, BsGithub, BsFileTextFill } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import { MdEmail } from "react-icons/md";

function Footer(props) {
  const minOpacity = 0.6;
  const [shouldTransition, setShouldTransition] = useState(false);
  const [gradientOpacity, setGradientOpacity] = useState(minOpacity);
  const gradientRef = useRef(null);

  const handleMouseMove = (e) => {
    setShouldTransition(false);
    if (gradientRef.current) {
      const bounds = gradientRef.current.getBoundingClientRect();
      const centerX = bounds.left + bounds.width / 2;
      const centerY = bounds.top + bounds.height / 2;
      const distance = Math.sqrt(
        Math.pow(centerX - e.clientX, 2) + Math.pow(centerY - e.clientY, 2)
      );

      // Set the threshold distance (500 pixels)
      const thresholdDistance = 500;
      let opacity = 1;

      if (distance < thresholdDistance) {
        // Interpolate opacity between 0.3 and 1 based on distance
        const opacityRange = 1 - minOpacity; // Range between max and min opacities
        const normalizedDistance = distance / thresholdDistance; // Normalize distance to range from 0 to 1
        opacity = 1 - normalizedDistance * opacityRange; // Calculate interpolated opacity
        opacity = opacity < minOpacity ? minOpacity : opacity; // Ensure opacity does not go below 0.3
      } else {
        opacity = minOpacity;
      }

      setGradientOpacity(opacity);
    }
  };

  useEffect(() => {
    const resetOpacity = () => {
      setShouldTransition(true);
      setGradientOpacity(minOpacity);
    };

    window.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseleave", resetOpacity); // Reset when mouse leaves the window

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseleave", resetOpacity); // Clean up the listener
    };
  }, []);

  const gradientStyle = {
    background: generateNonLinearGradient(),
    opacity: gradientOpacity,
    transition: shouldTransition ? "opacity 0.3s ease-out" : "none",
  };

  return (
    <>
      <div className="footer">
        <div className="container-footer">
          <h1 className="section-title">Get In Touch</h1>
          <p className="footer-description">
            Feel free to reach out to me! You can find me trough any of the
            links below.
          </p>
          <div className="card-container">
            <a
              className="footer-card"
              href="https://www.linkedin.com/in/axel-nielsen-665a3811a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="card-content">
                <BsLinkedin className="card-icon" />
                <p>LinkedIn</p>
              </div>
              <BiLinkExternal />
            </a>

            <a
              className="footer-card"
              href="https://github.com/AxelNie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="card-content">
                <BsGithub className="card-icon" />
                <p>GitHub</p>
              </div>
              <BiLinkExternal />
            </a>

            <a
              className="footer-card"
              href="/axel_nielsen_resume.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="card-content">
                <BsFileTextFill className="card-icon" />
                <p>My Resume</p>
              </div>
              <BiLinkExternal />
            </a>

            <a className="footer-card" href="mailto:axel.nie17@gmail.com">
              <div className="card-content">
                <MdEmail className="card-icon" />
                <p>Email</p>
              </div>
              <BiLinkExternal />
            </a>
          </div>
          <div className="seperator-line" />
          <div className="footer-credit">
            <p className="footer-credit-text">
              Website Designed and Developed by&nbsp;
              <a
                href="https://www.linkedin.com/in/axel-nielsen-665a3811a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="nowrap">Axel Nielsen</span>
              </a>
            </p>

            <div
              className={`gradient-background ${
                shouldTransition ? "opacity-transition" : ""
              }`}
              ref={gradientRef}
              style={gradientStyle}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function generateNonLinearGradient() {
  // Define your color stops and non-linearity
  const opacity = 0.25;

  const colorStops = [
    { color: "rgba(255, 255, 255, " + 1 * opacity + ")", stop: 0 },
    { color: "rgba(255, 255, 255, " + 0.5 * opacity + ")", stop: 15 }, // Closer to the previous stop
    { color: "rgba(255, 255, 255, " + 0.2 * opacity + " )", stop: 45 }, // Start of more spread out stops
    { color: "rgba(255, 255, 255, 0)", stop: 70 }, // More spread out
    { color: "rgba(255, 255, 255, 0)", stop: 100 },
  ];

  // Generate the gradient string
  const gradient = colorStops
    .map((stop) => `${stop.color} ${stop.stop}%`)
    .join(", ");

  return `radial-gradient(at bottom, ${gradient})`;
}

export default Footer;
