import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../App.scss";
import "../../navbar.scss";
import { IoPersonSharp, IoSchoolSharp } from "react-icons/io5";
import { scroller } from "react-scroll";
import { RiHome2Fill } from "react-icons/ri";
import { IoIosListBox } from "react-icons/io";

function NavBarDesktop() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = Math.max(0, window.pageYOffset - 300); //Offset
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 1100,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <div
      className="navbar-desktop"
      style={{
        backgroundColor:
          "rgba(10,12,23, " + Math.min(0.6, scrollPosition / 800) + ")",
      }}
    >
      <div className="container-navbar">
        <Link to={"/"}>
          <div className="logo" onClick={() => scrollTo("Hero")}>
            <img src="./logo.webp" alt="Axel" />
          </div>
        </Link>
        <div className="navbar-nav">
          <div className="item" onClick={() => scrollTo("Hero")}>
            <Link to={"/"} className="link">
              <RiHome2Fill className="menu-icon" />
              Home
            </Link>
          </div>
          <div className="item" onClick={() => scrollTo("AboutMe")}>
            <Link to={"/#about-me"} className="link">
              <IoPersonSharp className="menu-icon" />
              About Me
            </Link>
          </div>
          <div className="item" onClick={() => scrollTo("Projects")}>
            <Link to={"/#projects"} className="link">
              <IoIosListBox className="menu-icon" />
              Projects
            </Link>
          </div>
          <div className="item" onClick={() => scrollTo("Education")}>
            <Link to={"/#education"} className="link">
              <IoSchoolSharp className="menu-icon" />
              Education
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBarDesktop;
