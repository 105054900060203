import React from "react";
import "../Hero.scss";

function HeroImageSkeleton() {
  return (
    <div className="img-container-skeleton">
      <div className="circle-skeleton" />
    </div>
  );
}

export default HeroImageSkeleton;
