import React, { useEffect, useState } from "react";
import "./TextBox.scss";
import "aos/dist/aos.css";

function TextBox(props) {
  return (
    <div className="text-box">
      {props.image ? (
        <div className="image-container-text-box">
          <img className="text-box-img" src={props.image} alt={"Bild på mig"} />
        </div>
      ) : null}
      <h3 className="title-box">{props.title}</h3>
      <p className="text-for-box">{props.text}</p>
    </div>
  );
}

export default TextBox;
