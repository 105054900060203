import React, { Suspense } from "react";
import "./Hero.scss";
import MasterButton from "./MasterButton";
import { Parallax } from "react-scroll-parallax";
import { scroller } from "react-scroll";
import HeroImageSkeleton from "./small/HeroImageSkeleton";
//import InteractiveBackground from "./InteractiveBackground";
import { BsLinkedin, BsGithub } from "react-icons/bs";

const InteractiveBackground = React.lazy(() =>
  import("./InteractiveBackground")
);

const HeroImage = React.lazy(() => import("./small/HeroImage"));

function Hero(props) {
  function scrollTo(element) {
    console.log("kör");
    scroller.scrollTo(element, {
      duration: 1100,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <div className="hero">
      <div className="container">
        <Parallax className="left">
          <h1 className="hero-title">AXEL NIELSEN</h1>
          <h3 className="sub-title">
            An engineering student with an interest in full-stack development
            and data engineering.
          </h3>
          <div className="hero-buttons">
            <MasterButton onClick={() => scrollTo("Projects")} loading={false}>
              My Projects
            </MasterButton>
            <div className="hero-links">
              <a
                href="https://www.linkedin.com/in/axel-nielsen-665a3811a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsLinkedin />
              </a>
              <a
                href="https://github.com/AxelNie"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsGithub />
              </a>
            </div>
          </div>
        </Parallax>
        <div className="right">
          <Suspense fallback={<HeroImageSkeleton />}>
            <HeroImage />
          </Suspense>
        </div>
      </div>
      {props.windowDimensions.width > 500 ? (
        <Suspense fallback={null}>
          <InteractiveBackground className="interactive-background" />
        </Suspense>
      ) : null}
    </div>
  );
}

export default Hero;
