import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./ProjectCard.scss";
import Marquee from "react-fast-marquee";

const ProjectCard = React.memo(
  ({ project, handleToggle, windowDimensions }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [marqueePlaying, setMarqueePlaying] = useState(false);
    const [keyMarquee, setKeyMarquee] = useState(generateRandomNumber());
    const [direction] = useState("left"); // If direction doesn't change, no need to manage it in state
    const [speed] = useState(40); // Same for speed, unless it's dynamic

    // Memoize handlers to prevent unnecessary re-renders
    const handleMouseEnter = useCallback(() => setIsHovering(true), []);
    const handleMouseLeave = useCallback(() => setIsHovering(false), []);
    const handleClick = useCallback(
      () => handleToggle(project),
      [handleToggle, project]
    );

    // Memoize background style to prevent re-creation on each render
    const mystyle = useMemo(
      () => ({
        background: `url(${project.fields.thumbnail.fields.file.url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100%",
      }),
      [project.fields.thumbnail.fields.file.url]
    );

    // Memoize marquee content to prevent re-creation on each render
    const marqueeContent = useMemo(
      () =>
        project.fields.technologies.map((tech, index) => (
          <div className="tech-project-card" key={index}>
            {renderTech(tech.fields, isHovering)}
          </div>
        )),
      [project.fields.technologies, isHovering]
    );

    const h3Ref = useRef(null);
    const marqueeContentRef = useRef(null);

    // Optimize effect for playing marquee
    useEffect(() => {
      if (!isHovering) {
        setMarqueePlaying(false);
        return;
      }

      const duration =
        calculateDuration(speed, h3Ref, marqueeContentRef) * 1000;
      if (duration !== -1) {
        setMarqueePlaying(true);
        const timeoutId = setTimeout(() => setMarqueePlaying(false), duration);
        return () => clearTimeout(timeoutId);
      }
    }, [isHovering, speed]);

    // Update keyMarquee only when necessary
    useEffect(() => {
      if (!isHovering) {
        setKeyMarquee((prevKey) => prevKey + 1);
      }
    }, [isHovering]);

    return (
      <div
        className="card"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={isHovering ? "top-compressed" : "top"}
          style={mystyle}
        />
        <div className={isHovering ? "bottom-expanded" : "bottom"}>
          <div
            className={isHovering ? "text-wrapper expanded" : "text-wrapper"}
          >
            <h3 ref={h3Ref} className="title">
              {project.fields.title}
            </h3>
            <div
              className={isHovering ? "p-container expanded" : "p-container"}
            >
              <p
                className={
                  isHovering || windowDimensions.width > 700
                    ? "summary"
                    : "summary-invisible"
                }
              >
                {project.fields.summary}
              </p>
            </div>
          </div>
          {project.fields.technologies && windowDimensions.width > 670 ? (
            <Marquee
              play={marqueePlaying}
              speed={speed}
              className="tags"
              loop={0}
              key={keyMarquee}
              direction={direction}
            >
              <div ref={marqueeContentRef} className="marquee-content">
                {marqueeContent}
              </div>
            </Marquee>
          ) : (
            <div className="tags">{marqueeContent}</div>
          )}
        </div>
      </div>
    );
  }
);

export default ProjectCard;

const renderTech = (tech, isHovering) => (
  <img
    className={isHovering ? "img_icon hovering" : "img_icon"}
    src={`https:${tech?.image?.fields?.file?.url}`}
    alt=""
  />
);

const calculateDuration = (speed, h3Ref, marqueeContentRef) => {
  const h3Width = h3Ref.current?.offsetWidth || 0;
  const marqueeContentWidth = marqueeContentRef.current?.offsetWidth || 0;
  if (marqueeContentWidth <= h3Width) return -1;
  return (marqueeContentWidth - h3Width) / speed;
};

function generateRandomNumber() {
  return Math.floor(Math.random() * 10000001);
}
