import React, { useEffect, useState } from "react";
import "./Projects.scss";
import "aos/dist/aos.css";
import getProjects from "../services/projects"; // Adjusted import statement
import ProjectCard from "./ProjectCard";
import Aos from "aos";
import Modal from "./Modal"; // <-- Import your custom Modal component instead of Backdrop
import ProjectWindow from "./ProjectWindow";
import MasterButton from "./MasterButton";
import { FaChevronDown } from "react-icons/fa6";

function Projects(props) {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [preview, setPreview] = useState(true);
  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleToggle = (selectedProject) => {
    setSelectedProject(selectedProject);
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  useEffect(() => {
    retrieveInitialProjects();
  }, []);

  useEffect(() => {
    console.log("buttonLoading", buttonLoading);
  }, [buttonLoading]);

  const retrieveInitialProjects = () => {
    getProjects(3, 0)
      .then((response) => {
        setProjects(response.items);
        setNumberOfProjects(response.total);
        console.log("getting projects: ", response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveAllRemainingProjects = () => {
    setButtonLoading(true);
    getProjects(20, 3)
      .then((response) => {
        if (response.items.length !== 0) {
          setProjects([...projects, ...response.items]);
        }
        setPreview(false);
        setButtonLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let delay = 0;
  return (
    <>
      <div className="projects">
        <div className="container-project">
          <h1
            data-aos="fade-up-custom"
            data-aos-anchor-placement="top-bottom"
            className="section-title"
          >
            Projects
          </h1>
          <div className="project-list">
            {projects?.map((project, index) => {
              return (
                <div key={index} className="project-card-container">
                  <div
                    className="project-card"
                    data-aos="fade-up-custom"
                    data-aos-anchor-placement="top-bottom"
                    id={project.sys.id}
                  >
                    <ProjectCard
                      project={project}
                      handleToggle={handleToggle}
                      selectedProject={selectedProject}
                      windowDimensions={props.windowDimensions}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {numberOfProjects > 3 && preview ? (
            <div className="button-container">
              <div className="button-wrapper">
                <MasterButton
                  onClick={() => retrieveAllRemainingProjects()}
                  loading={buttonLoading}
                >
                  <FaChevronDown className="button-icon" />
                  Load projects ({numberOfProjects - 3})
                </MasterButton>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        title={selectedProject?.fields?.title}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        {" "}
        <ProjectWindow
          props={selectedProject}
          windowDimensions={props.windowDimensions}
        />
      </Modal>
    </>
  );
}

export default Projects;
