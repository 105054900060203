import React, { useEffect } from "react";
import { Element, scroller } from "react-scroll";
import Hero from "./Hero.js";
import Projects from "./Projects.js";
import AboutMe from "./AboutMe.js";
import Education from "./Education.js";
import Footer from "./Footer.js";

function Start(props) {
  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash) {
        scroller.scrollTo(window.location.hash.slice(1), {
          duration: 1000,
          offset: 170,
          delay: 300,
          smooth: "easeInOut",
        });
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);

    // If there is a hash in the URL on load, scroll to the respective section
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, []);

  return (
    <div>
      <Element name="Hero">
        <Hero windowDimensions={props.windowDimensions} />
      </Element>

      <Element name="AboutMe">
        <AboutMe windowDimensions={props.windowDimensions} />
      </Element>

      <Element name="Projects" id="Projects">
        <Projects windowDimensions={props.windowDimensions} />
      </Element>

      <Element name="Education" id="Education">
        <Education windowDimensions={props.windowDimensions} />
      </Element>

      <Element name="Footer" id="Footer">
        <Footer windowDimensions={props.windowDimensions} />
      </Element>
    </div>
  );
}

export default Start;
