import React, { useEffect, useState, useRef } from "react";
import "./Education.scss";
import "aos/dist/aos.css";
import {
  infoAboutEachYear,
  infoAboutAllCourses,
} from "../lib/EducationYearInfo";
import { FaChevronUp, FaChevronRight, FaChevronLeft } from "react-icons/fa6";

function Education(props) {
  const [selectedYear, setSelectedYear] = useState(null);
  const [hideCourses, setHideCourses] = useState(true);
  const [horizontalTransition, setHorizontalTransition] = useState(false);

  const prevHideCoursesRef = useRef();

  useEffect(() => {
    const prevHideCourses = prevHideCoursesRef.current;

    if (prevHideCourses === true && hideCourses === false) {
      setTimeout(() => {
        setHorizontalTransition(true);
      }, 300);
    }
    if (prevHideCourses === false && hideCourses === true) {
      setHorizontalTransition(false);
    }

    prevHideCoursesRef.current = hideCourses;
  }, [hideCourses]);

  const onClickHide = () => {
    setHideCourses(true);
    setTimeout(() => {
      setSelectedYear(null);
    }, 300);
  };

  return (
    <>
      <div className="education">
        <div className="container-educations">
          <h1
            data-aos="fade-up-custom"
            data-aos-anchor-placement="top-bottom"
            className="section-title"
          >
            Education
          </h1>
          <p
            className="section-description"
            data-aos="fade-up-custom"
            data-aos-anchor-placement="top-bottom"
          >
            Through my studies in the Master of Science in Media Technology
            program, I have built a solid foundation in software development,
            with lots of hands-on experience turning code into real-world
            applications. I have grown a particular interest and expertise in
            web development, which I've been lucky to apply in various projects.
            In addtion, I've gained a good understanding of AI and machine
            learning, learning how to solve complex problems with algorithms and
            data structures. Alongside these, I got to know a lot about making
            data easy to understand trough visualization, theory behind graphics
            and image processing.
          </p>
          <Timeline
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            setHideCourses={setHideCourses}
            screenWidth={props.windowDimensions.width}
          />
          <div>
            <CoursesForYear
              currentYearIndex={selectedYear}
              onClickHide={onClickHide}
              hideCourses={hideCourses}
              horizontalTransition={horizontalTransition}
              setSelectedYear={setSelectedYear}
              screenWidth={props.windowDimensions.width}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Education;

function Timeline({
  selectedYear,
  setSelectedYear,
  setHideCourses,
  screenWidth,
}) {
  const progressInPercent = Math.round(calculateEducationProgress());
  const [hoveredYear, setHoveredYear] = useState(-1);
  const [timeoutId, setTimeoutId] = useState(null);
  const [enableSelector, setEnableSelector] = useState(false);
  const [animatedProgress, setAnimatedProgress] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const timelineRef = useRef(null);

  // Create an array for the years 1 to 5
  const years = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];

  const updateHoveredYear = (index, enable) => {
    let timer = 100;

    if (!enable) timer = 300;

    const newTimeoutId = setTimeout(() => {
      setHoveredYear(index);
      setEnableSelector(enable);
    }, timer); // Adjusted timeout duration for demonstration
  };

  const handleMouseEnter = (index) => {
    updateHoveredYear(index, true);
  };

  const handleMouseLeave = () => {
    updateHoveredYear(-1, false);
  };

  const handleMouseMove = (event) => {
    clearTimeout(timeoutId); // Clear any existing timeouts

    const timelineRect = event.currentTarget.getBoundingClientRect();
    const relativeX = (event.clientX - timelineRect.left) / timelineRect.width;
    const yearIndex = Math.floor(relativeX * years.length);

    const newTimeoutId = setTimeout(() => {
      setHoveredYear(yearIndex);
    }, 100); // Set a new timeout

    setTimeoutId(newTimeoutId);
  };

  const handleClick = (index) => {
    setHideCourses(index === selectedYear ? true : false);

    if (index === selectedYear) {
      setTimeout(() => {
        setSelectedYear(index === selectedYear ? null : index);
      }, 300);
    } else {
      setSelectedYear(index === selectedYear ? null : index);
    }
  };

  const animateProgress = (progressInPercent, duration) => {
    let start = null;

    const frame = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const easedProgress = easeOutCubic(progress / duration);
      const currentProgress = Math.min(
        progressInPercent * easedProgress,
        progressInPercent
      );

      setAnimatedProgress(currentProgress);

      if (progress < duration) {
        requestAnimationFrame(frame);
      }
    };

    requestAnimationFrame(frame);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasAnimated) {
          animateProgress(progressInPercent, 2000); // Duration 2000ms
          setHasAnimated(true); // Ensure that the animation only runs once
        }
      },
      {
        threshold: 0.1, // Trigger when at least 10% of the target is visible
      }
    );

    if (timelineRef.current) {
      observer.observe(timelineRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [hasAnimated, progressInPercent]);

  return (
    <>
      <div
        className="timeline"
        ref={timelineRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="timeline-background">
          <div className="hover-regions">
            <div
              className={
                enableSelector ? "hover-selector" : "hover-selector invisible"
              }
              onClick={() => handleClick(hoveredYear)}
              style={{ transform: "translateX(" + 100 * hoveredYear + "%)" }}
            />
            {years.map((_, index) => (
              <div
                key={index}
                className="hover-region"
                onClick={() => handleClick(index)}
                onMouseEnter={() => handleMouseEnter(index)}
              ></div>
            ))}
          </div>
        </div>
        <div className="timeline-bar"></div>
        <div
          className="timeline-bar progress"
          style={{ width: animatedProgress + "%" }}
        ></div>
        <div
          className="progress-indicator-wrapper"
          style={{ width: animatedProgress + "%" }}
        >
          <div className="process-indicator">
            <div className="triangle-up"></div>
            <div className="progress-text">
              <p>{Math.round(animatedProgress)}%</p>
            </div>
          </div>
        </div>
        <div className="timeline-labels">
          {years.map((year, index) => (
            <div
              key={index}
              className={`timeline-label ${
                index % 2 === 0 ? "above" : "below"
              } ${selectedYear === index ? "hovered" : ""}`}
              style={{ left: `${index * 20 + 10}%` }} // Set specific positions
              onMouseEnter={() => handleMouseEnter(index)}
            >
              {year}
            </div>
          ))}
        </div>
      </div>
      <div className={hoveredYear === -1 ? "hover-info" : "hover-info active"}>
        {hoveredYear === -1
          ? screenWidth > 600
            ? "Hover over a year on the timeline to reveal more info"
            : "Click on a year on the timeline to reveal more info"
          : infoAboutEachYear[hoveredYear]}
      </div>
    </>
  );
}

function CoursesForYear({
  currentYearIndex,
  onClickHide,
  hideCourses,
  horizontalTransition,
  setSelectedYear,
  screenWidth,
}) {
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const totalYears = infoAboutAllCourses.length;

  const handlePrevClick = () => {
    setSelectedYear(Math.max(currentYearIndex - 1, 0));
  };

  const handleNextClick = () => {
    setSelectedYear(Math.min(currentYearIndex + 1, 4));
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentYearIndex]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevClick();
    } else if (event.key === "ArrowRight") {
      handleNextClick();
    } else if (event.key === "ArrowUp") {
      onClickHide();
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight);
    }
  }, [hideCourses]);

  const handleTransitionEnd = () => {
    // Handle the end of transition if needed
  };

  const style = !hideCourses
    ? { height: `${containerHeight}px` } // If visible, set height to the calculated height
    : { height: "0px" }; // If not visible, set height to 0

  return (
    <div
      className="courses-container"
      style={style}
      ref={containerRef}
      onTransitionEnd={handleTransitionEnd}
    >
      {/* Overlay buttons container */}
      {hideCourses ? null : (
        <div className="slide-button-overlay">
          {currentYearIndex === 0 ? null : (
            <button
              className="slide-button leftbutton"
              onClick={handlePrevClick}
            >
              <FaChevronLeft className="slide-button-icon" />
            </button>
          )}
          {currentYearIndex === 4 ? null : (
            <button
              className="slide-button rightbutton"
              onClick={handleNextClick}
            >
              <FaChevronRight className="slide-button-icon" />
            </button>
          )}
        </div>
      )}

      <div
        className="courses-wrapper-horizontal"
        style={{
          transform: `translateX(${-currentYearIndex * 100}%)`,
          transition: horizontalTransition ? "transform 0.3s ease-out" : "none",
        }}
      >
        <div
          className={`courses-wrapper-vertical ${hideCourses ? "hidden" : ""}`}
        >
          {infoAboutAllCourses.map((yearCourses, yearIndex) => (
            <div
              key={yearIndex}
              className={
                `year-courses ` +
                (yearIndex === currentYearIndex && !hideCourses
                  ? "active"
                  : "hidden")
              }
            >
              {yearCourses.map((course, index) => (
                <div key={index} className="course-wrapper">
                  <h2 className="course-title">{course.name}</h2>
                  {screenWidth > 600 && (
                    <p className="course-code">{course.courses_code}</p>
                  )}
                  <p className="course-grade">Grade: {course.grade}</p>
                  {course.description && (
                    <p className="course-description">{course.description}</p>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {currentYearIndex !== null ? (
        <div className="hide-button" onClick={onClickHide}>
          <FaChevronUp className="icon" />
          Hide courses
        </div>
      ) : null}
    </div>
  );
}

function calculateEducationProgress() {
  // Convert the input strings to Date objects
  const startDate = new Date("2019-08-20");
  const endDate = new Date("2024-06-20");
  const currentDate = new Date();

  // Calculate the total duration of the education in milliseconds
  const totalDuration = endDate - startDate;

  // Calculate the elapsed time in milliseconds
  const elapsedTime = currentDate - startDate;

  // Calculate the percentage completion
  const percentageCompletion = (elapsedTime / totalDuration) * 100;

  // Ensure the percentage is within the 0-100 range
  return Math.min(Math.max(percentageCompletion, 0), 100);
}

//Helper functions

// Ease-out function
const easeOutCubic = (t) => --t * t * t + 1;
