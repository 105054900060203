import client from "./contentfulClient";

const getProjects = async (limit = 20, skip = 0) => {
  try {
    const response = await client.getEntries({
      content_type: "portfolio",
      limit,
      skip,
      order: "sys.createdAt",
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getProjects;
