export const infoAboutEachYear = [
  "First year includes introductory courses in mathematics, programming (Java & C++) and web programming.",
  "Second year focuses on higher-level math, physics, C++ coding, 3D graphics, graphics theory, and basics of designing interfaces.",
  "Third year covers automatic control, image processing, data visualization, simulation, and a bachelor's thesis project in web.",
  "First master's year covers imaging technology, network security, advanced C++ and web programming, machine learning, AI, and algorithms.",
  "Final master's year covers AI, databases and web, visualization, and includes a thesis project.",
];

export const infoAboutAllCourses = [
  [
    {
      name: "Foundation Course in Mathematics",
      courses_code: "TNA001",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Programming",
      courses_code: "TND012",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Digital Media",
      courses_code: "TNM088",
      credits: 6,
      grade: "G",
      description: "",
    },
    {
      name: "Linear Algebra",
      courses_code: "TNA002",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Electronic Publishing",
      courses_code: "TNMK30",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Calculus I",
      courses_code: "TNA003",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Object-Oriented Programming",
      courses_code: "TND002",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Applied Mathematics in Science and Technology",
      courses_code: "TNA005",
      credits: 6,
      grade: "G",
      description: "",
    },
    {
      name: "Calculus II",
      courses_code: "TNA004",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Computer Graphics",
      courses_code: "TNM046",
      credits: 6,
      grade: 5,
      description: "",
    },
  ],
  [
    {
      name: "Calculus III",
      courses_code: "TNA006",
      credits: 6,
      grade: 3,
      description: "",
    },
    {
      name: "Mechanics and Wave Physics",
      courses_code: "TNE043",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Vector Analysis",
      courses_code: "TNA007",
      credits: 6,
      grade: 3,
      description: "",
    },
    {
      name: "Programming in C++",
      courses_code: "TNG033",
      credits: 6,
      grade: 3,
      description: "",
    },
    {
      name: "Communication and User Interfaces",
      courses_code: "TNM040",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Graphic Arts",
      courses_code: "TNM059",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Applied Transform Theory",
      courses_code: "TNG032",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "3-D Computer Graphics",
      courses_code: "TNM061",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Statistics",
      courses_code: "TNG006",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Signals and Systems",
      courses_code: "TNG015",
      credits: 6,
      grade: 5,
      description: "",
    },
  ],
  [
    {
      name: "Automatic Control",
      courses_code: "TNG028",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Physics of Sound",
      courses_code: "TFYA65",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Image Processing and Analysis",
      courses_code: "TNM087",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Practical Data Visualization and Virtual Reality",
      courses_code: "TNM093",
      credits: 6,
      grade: "G",
      description: "",
    },
    {
      name: "Modelling and Simulation",
      courses_code: "TNG022",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Modelling Project",
      courses_code: "TNM085",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Data Structures",
      courses_code: "TND004",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Media Technology - Bachelor Project",
      courses_code: "TNM094",
      credits: 18,
      grade: "G",
      description: "",
    },
  ],
  [
    {
      name: "Imaging Technology",
      courses_code: "TNM089",
      credits: 6,
      grade: 4,
      description: "",
    },
    {
      name: "Network Programming and Security",
      courses_code: "TNM031",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Advanced Image Processing",
      courses_code: "TNM034",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Machine Learning for Social Media",
      courses_code: "TNM108",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Interaction Programming",
      courses_code: "TDDC73",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Neural Networks and Learning Systems",
      courses_code: "TBMI26",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Advanced Programming in C++",
      courses_code: "TDDD38",
      credits: 6,
      grade: 3,
      description: "",
    },
    {
      name: "Artificial Intelligence - Principles and Techniques",
      courses_code: "TNM096",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Advanced Web Programming",
      courses_code: "TDDD27",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Algorithmic Problem Solving",
      courses_code: "TDDD95",
      credits: 6,
      grade: 3,
      description: "",
    },
  ],
  [
    {
      name: "Artificial Inteligence for Interactive Media, Porject",
      courses_code: "TNM114",
      credits: 6,
      grade: "G",
      description: "",
    },
    {
      name: "Database and Web Based Systems",
      courses_code: "5TF048",
      credits: 7.5,
      grade: 5,
      description: "",
    },
    {
      name: "Scientific Visualization",
      courses_code: "TNM067",
      credits: 6,
      grade: 5,
      description: "",
    },
    {
      name: "Matrix Methods for AI",
      courses_code: "TNA010",
      credits: 6,
      grade: "-",
      description: "",
    },
    {
      name: "Scientific Method",
      courses_code: "TNM107",
      credits: 6,
      grade: "-",
      description: "",
    },
    {
      name: "Master Thesis",
      courses_code: "-",
      credits: 30,
      grade: "-",
      description: "",
    },
  ],
];
