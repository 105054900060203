import React, { useEffect } from "react";
import "./AboutMe.scss";
import "aos/dist/aos.css";
import Aos from "aos";
import TextBox from "./small/TextBox";
import portrait from "../images/Axel_porträttbild_transparent.webp";
import { Parallax } from "react-scroll-parallax";
import MasterButton from "./MasterButton";
import { HiDocumentText } from "react-icons/hi";

function AboutMe(props) {
  useEffect(() => {
    Aos.init({ duration: 600 });
  });

  return (
    <>
      <div className="aboutme">
        <div className="container-about">
          <h1
            data-aos="fade-up-custom"
            data-aos-anchor-placement="top-bottom"
            className="section-title"
          >
            About Me
          </h1>
          <div className="about-me-content">
            <Parallax
              className="box1"
              data-aos="fade-up"
              data-aos-offset="100px"
              data-aos-duration="800"
              data-aos-easing="ease-out"
              speed={props.windowDimensions.width < 1000 ? 0 : 12}
            >
              <TextBox
                className="box-content"
                title={"What I'm Up To"}
                text={
                  "Right now, I'm happily diving into full-stack development both professionally and in my free time. On my free time, my current focus is on a project called 'Restidkollen', which you can find more about in the Projects section below."
                }
              />
            </Parallax>
            <div
              data-aos="fade-up"
              data-aos-offset="100px"
              data-aos-duration="800"
              data-aos-easing="ease-out"
              className="box3"
            >
              <TextBox
                image={portrait}
                title={"Who Am I?"}
                text={
                  "I am studying my 5th year for a master's degree in media technology in Norrköping and would describe me as a technical computer geek with an interest in visuals and the techniques behind them. Media technology is therefore the perfect mix for me, a very technical education with programming while I get to express my creativity in projects with others! In addition to performing well in my courses, I enjoy being an active student, programming on personal projects, as well as working as an IT consultant."
                }
              />
            </div>
            <Parallax
              className="box2"
              data-aos="fade-up"
              data-aos-offset="100px"
              data-aos-duration="800"
              data-aos-easing="ease-out"
              speed={props.windowDimensions.width < 1000 ? 0 : -12}
            >
              <TextBox
                title={"My Interestes"}
                text={
                  "When I am taking a break from the computer I love to take a walk and bring my camera. Regardless of whether it is in the forest or in the city there is something therapeutic about strolling with a camera in my hand and paying attention to my surroundings."
                }
              />
            </Parallax>
            <div className="cv-container">
              <a
                href="/axel_nielsen_resume.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MasterButton loading={false}>
                  <HiDocumentText className="button-icon" /> My Resume
                </MasterButton>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMe;
