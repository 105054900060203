import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./navbar.scss";
import "./App.scss";
import Projects from "./components/Projects.js";
import AboutMe from "./components/AboutMe.js";
import Start from "./components/Start.js";
import NavBarDesktop from "./components/small/NavBarDesktop";
import NavBarMobile from "./components/small/NavBarMobile";

function App() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const setScrollPosition = () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    };
    window.addEventListener("scroll", setScrollPosition);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", setScrollPosition);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div>
      {windowDimensions.width < 600 ? <NavBarMobile /> : <NavBarDesktop />}

      <div className="main">
        <Routes>
          <Route
            path="/"
            element={<Start windowDimensions={windowDimensions} />}
          />
          <Route path="/om-mig" element={<AboutMe />} />
          <Route path="/mina-projekt" element={<Projects />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}
