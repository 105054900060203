import React, { useState, useRef, useEffect } from "react";
import "./Tooltip.scss";

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const node = useRef();

  useEffect(() => {
    const nodeEl = node.current;

    const showTooltip = () => {
      setIsVisible(true);
    };

    const hideTooltip = () => {
      setIsVisible(false);
    };

    nodeEl.addEventListener("mouseenter", showTooltip);
    nodeEl.addEventListener("mouseleave", hideTooltip);

    return () => {
      nodeEl.removeEventListener("mouseenter", showTooltip);
      nodeEl.removeEventListener("mouseleave", hideTooltip);
    };
  }, []);

  return (
    <div className="tooltip-container" ref={node}>
      {children}
      <div
        className={`tooltip-box tooltip-bottom ${isVisible ? "visible" : ""}`}
      >
        {text}
        {/* <span className={`arrow ${position}`}></span> */}
        <div class="triangle"></div>
      </div>
    </div>
  );
};

export default Tooltip;
